module.exports = [{
      plugin: require('/Users/devall/projects/side/opensource/songwang.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-26982505-1"},
    },{
      plugin: require('/Users/devall/projects/side/opensource/songwang.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/devall/projects/side/opensource/songwang.io/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
